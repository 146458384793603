import React from 'react';

import angularIcon from '../assets/svg/technologies/angular.svg';
import awsIcon from '../assets/svg/technologies/aws.svg';
import gatsbyIcon from '../assets/svg/technologies/gatsby_icon.svg';
import jsIcon from '../assets/svg/technologies/js.svg';
import nodeJsIcon from '../assets/svg/technologies/nodejs.svg';
import serverlessIcon from '../assets/svg/technologies/serverless_icon.svg';
import typescriptIcon from '../assets/svg/technologies/typescript.svg';

const Technologies = () => (
  <div className="col-md-6">
    <div className="row justify-content-end">
      <div className="col-3 mb-4">
        <div
          className="d-block bg-white avatar avatar-lg shadow-sm rounded-circle p-1 mt-n3 ml-5"
          data-aos="fade-up"
        >
          <img className="avatar-img" src={typescriptIcon} alt="typescript" />
        </div>
      </div>
      <div className="col-4 mb-4">
        <div
          className="d-block bg-white avatar avatar-xl shadow-sm rounded-circle p-1 mx-auto mt-5"
          data-aos="fade-up"
          data-aos-delay="50"
        >
          <img className="avatar-img" src={awsIcon} alt="aws" />
        </div>
      </div>
      <div className="col-4 mb-4">
        <div
          className="d-block bg-white avatar avatar-xl shadow-sm rounded-circle p-3 ml-auto"
          data-aos="fade-up"
          data-aos-delay="150"
        >
          <img className="avatar-img" src={nodeJsIcon} alt="node.js" />
        </div>
      </div>
    </div>

    <div className="row">
      <div className="col-3 offset-1 my-4">
        <div
          className="d-block bg-white avatar avatar-xl shadow-sm rounded-circle p-1 ml-auto"
          data-aos="fade-up"
          data-aos-delay="200"
        >
          <img className="avatar-img" src={angularIcon} alt="angular" />
        </div>
      </div>
      <div className="col-3 offset-2 my-4">
        <div
          className="d-block bg-white avatar avatar-xl shadow-sm rounded-circle p-4 ml-auto"
          data-aos="fade-up"
          data-aos-delay="250"
        >
          <img className="avatar-img" src={serverlessIcon} alt="serverless" />
        </div>
      </div>
    </div>

    <div className="row d-none d-md-flex">
      <div className="col-6">
        <div
          className="d-block bg-white avatar avatar-lg shadow-sm rounded-circle p-3 ml-auto"
          data-aos="fade-up"
          data-aos-delay="300"
        >
          <img className="avatar-img" src={gatsbyIcon} alt="gatsby" />
        </div>
      </div>
      <div className="col-6 mt-6">
        <div
          className="d-block bg-white avatar avatar-xl shadow-sm rounded-circle p-4 ml-auto"
          data-aos="fade-up"
          data-aos-delay="350"
        >
          <img className="avatar-img" src={jsIcon} alt="javascript" />
        </div>
      </div>
    </div>
  </div>
);

export default Technologies;
