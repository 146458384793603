import { Link, graphql } from 'gatsby';
import { GatsbyImage, getImage, StaticImage } from 'gatsby-plugin-image';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

import SEO from '../components/Seo';
import Technologies from '../components/Technologies';

const BlogIndex = ({ data }) => {
  const posts = data.allMarkdownRemark.nodes;
  const { i18n, t } = useTranslation();

  if (posts.length === 0) {
    return (
      <>
        <SEO
          title={t('meta.blog.title')}
          lang={i18n.language}
          description={t('meta.blog.description')}
        />
        <p>
          No blog posts found. Add markdown posts to &quot;content/blog&quot; (or the directory you
          specified for the &quot;gatsby-source-filesystem&quot; plugin in gatsby-config.js).
        </p>
      </>
    );
  }

  const firstPost = posts[0];
  const otherPosts = posts.slice(1);

  return (
    <>
      <SEO
        title={t('meta.blog.title')}
        lang={i18n.language}
        description={t('meta.blog.description')}
      />

      <section className="space-top-3 space-top-lg-2 space-bottom-2">
        <div className="gradient-y-gray position-relative">
          <div className="container position-relative z-index-2">
            <div className="row justify-content-lg-between align-items-md-center">
              <div className="col-md-6 col-lg-5 mb-5 mb-md-0">
                <div className="mb-4">
                  <h1 className="display-4">{t('blog.title')}</h1>
                  <p>{t('blog.subtitle')}</p>
                </div>
              </div>

              <Technologies />
            </div>
          </div>
          <figure>
            <svg
              preserveAspectRatio="none"
              xmlns="http://www.w3.org/2000/svg"
              x="0px"
              y="0px"
              viewBox="0 0 1920 100.1"
            >
              <path fill="#fff" d="M0,0c0,0,934.4,93.4,1920,0v100.1H0L0,0z" />
            </svg>
          </figure>
        </div>
      </section>

      <section className="container space-2 space-top-lg-2 space-bottom-lg-3">
        <div className="row font-weight-bold text-center mb-2">
          <h2 className="col-12 h3">Top Tags</h2>
        </div>

        <div className="row justify-content-center mb-5 mb-md-6">
          <div className="d-sm-flex align-items-sm-center text-center text-sm-left">
            <button className="btn btn-xs btn-soft-secondary btn-pill mx-1 mb-2" type="button">
              Web Development
            </button>
            <button className="btn btn-xs btn-soft-secondary btn-pill mx-1 mb-2" type="button">
              AWS
            </button>
            <button className="btn btn-xs btn-soft-secondary btn-pill mx-1 mb-2" type="button">
              Serverless
            </button>
            <button className="btn btn-xs btn-soft-secondary btn-pill mx-1 mb-2" type="button">
              Javascript
            </button>
            <button className="btn btn-xs btn-soft-secondary btn-pill mx-1 mb-2" type="button">
              Angular
            </button>
            <button className="btn btn-xs btn-soft-secondary btn-pill mx-1 mb-2" type="button">
              Node.js
            </button>
          </div>
        </div>

        <article className="card mb-5 border-0" itemScope itemType="http://schema.org/Article">
          <div className="row">
            <div className="col-lg-7 d-flex align-items-center">
              <Link
                to={firstPost.fields.slug}
                itemProp="url"
                className="position-relative d-inline-flex"
              >
                <GatsbyImage
                  className="border rounded"
                  image={getImage(firstPost.frontmatter.image)}
                />
              </Link>
            </div>

            <div className="col-lg-5">
              <div className="card-body d-flex flex-column h-100 p-4">
                <header>
                  <h2>
                    <Link to={firstPost.fields.slug} itemProp="url" className="text-inherit">
                      {firstPost.frontmatter.title || firstPost.fields.slug}
                    </Link>
                  </h2>
                </header>
                <section>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: firstPost.frontmatter.description || firstPost.excerpt,
                    }}
                    itemProp="description"
                  />
                </section>

                <div className="media align-items-center mt-auto">
                  <StaticImage
                    className="rounded-circle mr-3"
                    src="../assets/images/profile-pic.png"
                    alt="avatar"
                    layout="fixed"
                    width={43}
                    height={43}
                  />
                  <div className="media-body">
                    <span className="text-dark d-inline-block font-weight-bold">
                      {firstPost.frontmatter.author}
                    </span>
                    <small className="d-block">
                      {firstPost.frontmatter.date} ·{' '}
                      {Math.ceil(firstPost.fields.readingTime.minutes.toFixed(2))}{' '}
                      {t('blog.minRead')}
                    </small>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </article>

        <div className="row">
          {otherPosts.map((post, index) => {
            const title = post.frontmatter.title || post.fields.slug;
            const readingTime = Math.ceil(post.fields.readingTime.minutes.toFixed(2));

            return (
              // eslint-disable-next-line react/no-array-index-key
              <div key={index} className="col-md-6 mb-3">
                <article
                  className="card h-100 border-0"
                  itemScope
                  itemType="http://schema.org/Article"
                >
                  <div className="card-img-top position-relative border rounded">
                    <Link to={post.fields.slug} itemProp="url" className="position-relative">
                      <GatsbyImage image={getImage(post.frontmatter.image)} />
                    </Link>
                  </div>

                  <div className="card-body">
                    <header>
                      <h3>
                        <Link to={post.fields.slug} itemProp="url" className="text-inherit">
                          {title}
                        </Link>
                      </h3>
                    </header>
                    <section>
                      <p
                        dangerouslySetInnerHTML={{
                          __html: post.frontmatter.description || post.excerpt,
                        }}
                        itemProp="description"
                        className="mb-0"
                      />
                    </section>
                  </div>

                  <div className="card-footer bg-white border-0 pt-0">
                    <div className="media align-items-center mt-auto">
                      <StaticImage
                        className="rounded-circle mr-3"
                        src="../assets/images/profile-pic.png"
                        alt="avatar"
                        layout="fixed"
                        width={43}
                        height={43}
                      />
                      <div className="media-body">
                        <span className="text-dark d-inline-block font-weight-bold">
                          {post.frontmatter.author}
                        </span>
                        <small className="d-block">
                          {post.frontmatter.date} · {readingTime} {t('blog.minRead')}
                        </small>
                      </div>
                    </div>
                  </div>
                </article>
              </div>
            );
          })}
        </div>
      </section>
    </>
  );
};

export default BlogIndex;

export const pageQuery = graphql`
  query blogPage($locale: String) {
    allMarkdownRemark(
      filter: { fields: { locale: { eq: $locale } } }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      nodes {
        excerpt
        fields {
          slug
          locale
          readingTime {
            minutes
          }
        }
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          title
          description
          author
          image {
            childImageSharp {
              gatsbyImageData(placeholder: DOMINANT_COLOR)
            }
          }
        }
      }
    }
  }
`;
